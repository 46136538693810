




import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';
import { readHasReviewerAcccess } from '@/store/main/getters';


const routerGuardReviewer = async (to, from, next) => {

  if (!readHasReviewerAcccess(store)) {
    next('/main');
  } else {
      next();
  }
};

@Component
export default class Reviewer extends Vue {
  public beforeRouteEnter(to, from, next) {
    routerGuardReviewer(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routerGuardReviewer(to, from, next);
  }
}
